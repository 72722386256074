(function () {
    var publicationContreb = {
        $ContribsAjax: $('#ContribsAjax'),

        init: function () {
            publicationContreb.control();

            // check if the saved collateral panel in the session storage is core-collateral-info then we need to load the contribs again
            if (sessionStorage.getItem(sessionStorage.getItem('doi') + '_collateralPanel') === 'core-collateral-info') {
                publicationContreb.contribsAjaxHandler();
            }
        },

        control: function () {
            $(
                '.to-authors-affiliations,.collateral-pill #article_collateral_menu,.otherMetrics__section__content [data-tab="pill-authors__content"]'
            ).click(function () {
                publicationContreb.contribsAjaxHandler();
            });
        },

        contribsAjaxHandler: function () {
            if ($('#ContribsAjax').length) {
                $.ajax({
                    url: publicationContreb.$ContribsAjax.data('ajax'),
                    type: 'GET',
                    success: function (response) {
                        $('.publication-contribs-contianer').replaceWith(response);
                        $('.publication-contribs-contianer .loader').hide();
                    },
                    complete: function (e) {
                        if (e.status === 200) {
                            let $claimPublicationLink = $('.claimPublication'),
                                $claimPublicationButton = $('.claimButton'),
                                $cancelClaimButton = $('.cancelClaim'),
                                $pendingButton = $('.pending'),
                                $notMeButton = $('.notMe'),
                                $editLink = $('.editClaim'),
                                $publicationContribsContainer = $('.publication-contribs'),
                                showElement = $publicationContribsContainer.data('show'),
                                truncatedData,
                                $pubContribsLoa = $('.pubContribs .loa');

                            //Decide which message to show based on the Json
                            $(`.${showElement}`).removeClass('hidden');

                            // Hide "expand all" in case of no normalized authors are present
                            if ($('.normalizedAuthor').length < 1) {
                                $('.publication-contribs-contianer').find('.expand-all').hide();
                            }

                            // Due to some limitations from the FE side we had to pull some data from the authors section and append
                            // them to the widget
                            $pubContribsLoa.children('li:not(.normalizedAuthor)').each(function () {
                                var $this = $(this),
                                    $title = $this.find('.auth-name .unnormalized').attr('title'),
                                    $authorInst = $('.contributors .dropBlock [title="' + $title + '"]')
                                        .closest('.dropBlock')
                                        .find('.affiliations span[property="name"]'),
                                    $authorBiblioInst = $this.find('.auth-info .unnormalized');

                                if ($authorInst.length) {
                                    $authorBiblioInst.after($authorInst);
                                }
                            });
                            $pubContribsLoa.children('li.normalizedAuthor').each(function () {
                                var $this = $(this),
                                    doi = $this.find('.contrib-id').val(),
                                    citation = $(
                                        '.contributors .dropBlock__holder[data-doi="' + doi + '"] span[property="name"]'
                                    ),
                                    $pillText = $this.find('.auth-institution');

                                if (citation.length) {
                                    $pillText.text(citation.eq(0).text());
                                }
                            });

                            //Claim publication handler (hides Claim link and shows Cancel link)
                            $claimPublicationLink.click(function (e) {
                                e.preventDefault();
                                $claimPublicationButton.fadeIn('slow', function () {
                                    $(this).show();
                                });
                                $(this).hide();
                                $cancelClaimButton.show();
                            });

                            //Cancel handler
                            $cancelClaimButton.click(function (e) {
                                e.preventDefault();
                                $claimPublicationButton.fadeOut('slow', function () {
                                    $(this).hide();
                                    $(this).parent().find($pendingButton).hide();
                                });
                                $(this).hide();
                                if ($claimPublicationLink) {
                                    $claimPublicationLink.show();
                                }
                                if ($editLink) {
                                    $editLink.show();
                                }
                                if ($notMeButton) {
                                    $notMeButton.hide();
                                }
                            });

                            //Edit handler
                            $editLink.click(function (e) {
                                e.preventDefault();
                                $notMeButton.fadeIn('slow', function () {
                                    $(this).show();
                                });
                                $cancelClaimButton.show();
                                $editLink.hide();
                            });

                            // Not me (BUTTON) handler
                            $notMeButton.click(function (e) {
                                var elem = $(this);
                                e.preventDefault();
                                $notMeButton.fadeOut('slow', function () {
                                    $(this).hide();
                                });
                                $.ajax({
                                    url: $(this).parent().attr('href'),
                                    type: 'POST',
                                    // url:'/specs/products/acm/widgets/pub-contribs/js/ajax.json', // for local testing
                                    // type: "GET", // for local testing
                                    success: function (data) {
                                        $notMeButton.addClass('hidden');
                                        $pendingButton.removeClass('hidden');
                                        $('.pending').removeClass('hidden');
                                        $('.unclaim').addClass('hidden');
                                        elem.closest('li').append(
                                            '<p class="alert-msg--success"><i class="icon-checkmark"></i>' +
                                                data.message +
                                                '</p>'
                                        );
                                        $cancelClaimButton.hide();
                                    },
                                    complete: function (e) {
                                        if (e.status === 401) {
                                            elem.closest('li').append(
                                                '<p class="alert-msg--warning"><i aria-hidden="true" class="icon-warning-triangle"></i>' +
                                                    e.responseJSON.message +
                                                    '</p>\n'
                                            );
                                        } else if (e.status === 500) {
                                            elem.closest('li').append(
                                                '<p class="alert-msg--error"><i class="icon-notice"></i>' +
                                                    e.responseJSON.message +
                                                    '</p>'
                                            );
                                        }
                                    },
                                });
                            });

                            // Claim publication (BUTTON) text handler
                            $claimPublicationButton.click(function (e) {
                                var elem = $(this);
                                e.preventDefault();
                                $claimPublicationButton.fadeOut('slow', function () {
                                    $(this).hide();
                                    $('.icon-info, .cancelClaim, .claimPublication').hide();
                                });
                                $.ajax({
                                    url: $(this).parent().attr('href'),
                                    type: 'POST',
                                    // url:'/specs/products/acm/widgets/pub-contribs/js/ajax.json', // for local testing
                                    // type: "GET", // for local testing
                                    success: function (data) {
                                        $('.claim').addClass('hidden');
                                        $('.pending').removeClass('hidden');
                                        elem.closest('li').append(
                                            '<p class="alert-msg--success"><i class="icon-checkmark"></i>' +
                                                data.message +
                                                '</p>'
                                        );
                                    },
                                    complete: function (e) {
                                        if (e.status === 401) {
                                            elem.closest('li').append(
                                                '<p class="alert-msg--warning"><i aria-hidden="true" class="icon-warning-triangle"></i>' +
                                                    e.responseJSON.message +
                                                    '</p>\n'
                                            );
                                        } else if (e.status === 500) {
                                            elem.closest('li').append(
                                                '<p class="alert-msg--error"><i class="icon-notice"></i>' +
                                                    e.responseJSON.message +
                                                    '</p>'
                                            );
                                        }
                                    },
                                });
                            });

                            if (!document.getElementById('hidden-authors')) {
                                truncatedData = $('.article-citations .loa').data('originalContent');
                                if (truncatedData?.length) {
                                    $('.article-citations').append(
                                        `<div id="hidden-authors" class="hidden">${truncatedData}</div>`
                                    );
                                }
                            }

                            if (!$('.accordion:not(.pubContribs)').length) {
                                let $originalLink = $('.publication-contribs a');
                                // reinitialize accordion for ajax elements
                                if (!document.querySelector('.sections-navigator .hasNestedMenu')) {
                                    UX.accordion.init();
                                }

                                //disable functionality for RO mode
                                if ($('.ro-mode').length) {
                                    $originalLink.removeAttr('href');
                                    $originalLink.off('click');
                                    $originalLink.attr(
                                        'title',
                                        'This functionality is temporarily unavailable because maintenance is in progress'
                                    );
                                }
                            }
                        }
                    },
                });
            }
        },
    };
    UX.publicationContreb = publicationContreb;
})();

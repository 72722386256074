import './jquery.textfill.min.external';

(function () {
    var textFill = {
        init: function () {
            textFill.preDefined();
        },

        preDefined: function () {
            $(window).on('orientationchange load resized', function () {
                textFill.specElement($('.metric-value'));

                if ($('.top-rated-text').length) {
                    $('.top-rated-text').parents('.box-item__item').textfill({
                        innerTag: 'a',
                    });
                }

                setTimeout(function () {
                    textFill.specElement($('.metric-value'));

                    $('.top-rated-text').parents('.box-item__item').textfill({
                        innerTag: 'a',
                    });
                }, 250);
            });
        },

        specElement: function ($elem) {
            if ($elem.length) {
                $elem.textfill();
            }
        },
    };
    UX.textFill = textFill; // add to global namespace
})();
